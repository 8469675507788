/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddTimelineEventRequestBody,
    AddTimelineEventRequestBodyFromJSON,
    AddTimelineEventRequestBodyToJSON,
} from '../models/AddTimelineEventRequestBody'
import {
    AppointmentSuccessfullyAssignedResponse,
    AppointmentSuccessfullyAssignedResponseFromJSON,
    AppointmentSuccessfullyAssignedResponseToJSON,
} from '../models/AppointmentSuccessfullyAssignedResponse'
import {
    ArchiveEpisodeOfCareInfoBody,
    ArchiveEpisodeOfCareInfoBodyFromJSON,
    ArchiveEpisodeOfCareInfoBodyToJSON,
} from '../models/ArchiveEpisodeOfCareInfoBody'
import {
    ArchiveEpisodeOfCareRequestBody,
    ArchiveEpisodeOfCareRequestBodyFromJSON,
    ArchiveEpisodeOfCareRequestBodyToJSON,
} from '../models/ArchiveEpisodeOfCareRequestBody'
import {
    CalculatorInitialAnswersResponse,
    CalculatorInitialAnswersResponseFromJSON,
    CalculatorInitialAnswersResponseToJSON,
} from '../models/CalculatorInitialAnswersResponse'
import {
    CompleteCalculatorRequestBody,
    CompleteCalculatorRequestBodyFromJSON,
    CompleteCalculatorRequestBodyToJSON,
} from '../models/CompleteCalculatorRequestBody'
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    CreateOrAssignEpisodeToAnAppointmentRequestBody,
    CreateOrAssignEpisodeToAnAppointmentRequestBodyFromJSON,
    CreateOrAssignEpisodeToAnAppointmentRequestBodyToJSON,
} from '../models/CreateOrAssignEpisodeToAnAppointmentRequestBody'
import {
    EpisodeCreationInput,
    EpisodeCreationInputFromJSON,
    EpisodeCreationInputToJSON,
} from '../models/EpisodeCreationInput'
import {
    EpisodeOutput,
    EpisodeOutputFromJSON,
    EpisodeOutputToJSON,
} from '../models/EpisodeOutput'
import {
    EpisodeOwnerRequestBody,
    EpisodeOwnerRequestBodyFromJSON,
    EpisodeOwnerRequestBodyToJSON,
} from '../models/EpisodeOwnerRequestBody'
import {
    EpisodeRolesOutputSchema,
    EpisodeRolesOutputSchemaFromJSON,
    EpisodeRolesOutputSchemaToJSON,
} from '../models/EpisodeRolesOutputSchema'
import {
    EpisodeSummary,
    EpisodeSummaryFromJSON,
    EpisodeSummaryToJSON,
} from '../models/EpisodeSummary'
import {
    EpisodesRolesUpdatedResponse,
    EpisodesRolesUpdatedResponseFromJSON,
    EpisodesRolesUpdatedResponseToJSON,
} from '../models/EpisodesRolesUpdatedResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InternalServerErrorResponse,
    InternalServerErrorResponseFromJSON,
    InternalServerErrorResponseToJSON,
} from '../models/InternalServerErrorResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListCalculatorsResponse,
    ListCalculatorsResponseFromJSON,
    ListCalculatorsResponseToJSON,
} from '../models/ListCalculatorsResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ServiceUnavailableResponse,
    ServiceUnavailableResponseFromJSON,
    ServiceUnavailableResponseToJSON,
} from '../models/ServiceUnavailableResponse'
import {
    TimelineEpisodeOutput,
    TimelineEpisodeOutputFromJSON,
    TimelineEpisodeOutputToJSON,
} from '../models/TimelineEpisodeOutput'
import {
    TimelineEpisodeTimelineEventsOutput,
    TimelineEpisodeTimelineEventsOutputFromJSON,
    TimelineEpisodeTimelineEventsOutputToJSON,
} from '../models/TimelineEpisodeTimelineEventsOutput'
import {
    TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from '../models/TimelineEventOutput'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateConsultationRequestBody,
    UpdateConsultationRequestBodyFromJSON,
    UpdateConsultationRequestBodyToJSON,
} from '../models/UpdateConsultationRequestBody'
import {
    UpdateEpisodeRolesRequestBody,
    UpdateEpisodeRolesRequestBodyFromJSON,
    UpdateEpisodeRolesRequestBodyToJSON,
} from '../models/UpdateEpisodeRolesRequestBody'
import {
    UpdateQuestionsForEpisodeRequestBody,
    UpdateQuestionsForEpisodeRequestBodyFromJSON,
    UpdateQuestionsForEpisodeRequestBodyToJSON,
} from '../models/UpdateQuestionsForEpisodeRequestBody'
import {
    UpdateQuestionsForEpisodeResponse,
    UpdateQuestionsForEpisodeResponseFromJSON,
    UpdateQuestionsForEpisodeResponseToJSON,
} from '../models/UpdateQuestionsForEpisodeResponse'
import {
    UpdateVirtualCareTypeRequestBody,
    UpdateVirtualCareTypeRequestBodyFromJSON,
    UpdateVirtualCareTypeRequestBodyToJSON,
} from '../models/UpdateVirtualCareTypeRequestBody'
import {
    XopQuestionSearchOutputSchema,
    XopQuestionSearchOutputSchemaFromJSON,
    XopQuestionSearchOutputSchemaToJSON,
} from '../models/XopQuestionSearchOutputSchema'
import {
    XopQuestionSetSearchOutputSchema,
    XopQuestionSetSearchOutputSchemaFromJSON,
    XopQuestionSetSearchOutputSchemaToJSON,
} from '../models/XopQuestionSetSearchOutputSchema'


export interface AddTimelineEventRequest {
    id: string;
    addTimelineEventRequestBody?: AddTimelineEventRequestBody;
}
export interface ArchiveEpisodeOfCareRequest {
    id: string;
    archiveEpisodeOfCareRequestBody?: ArchiveEpisodeOfCareRequestBody;
}
export interface CreateCtmCompletedCalculatorRequest {
    episodeId: string;
    calculatorId: string;
    completeCalculatorRequestBody: CompleteCalculatorRequestBody;
}
export interface CreateOrAssignEpisodeToAnAppointmentRequest {
    createOrAssignEpisodeToAnAppointmentRequestBody?: CreateOrAssignEpisodeToAnAppointmentRequestBody;
}
export interface FetchArchiveEpisodeOfCareInfoRequest {
    id: string;
}
export interface FetchEpisodeConsultationHistoryRequest {
    id: string;
}
export interface FetchEpisodeOfCareRequest {
    id: string;
    page?: string;
    memberId?: string;
}
export interface FetchEpisodeOfCareTimelineEventsRequest {
    id: string;
    page?: string;
    order?: string;
}
export interface FetchEpisodeRolesRequest {
    episodeId: string;
}
export interface GetInitialCalculatorAnswersRequest {
    episodeId: string;
    calculatorId: string;
}
export interface ListCalculatorsRequest {
    episodeId: string;
    q?: string;
}
export interface SearchAvailableQuestionSetsForEpisodeRequest {
    episodeId: string;
    q?: string;
}
export interface SearchCommonQuestionsRequest {
    episodeId: string;
    q?: string;
}
export interface StartEpisodeOfCareRequest {
    episodeCreationInput: EpisodeCreationInput;
}
export interface UpdateConsultationRequest {
    id: string;
    updateConsultationRequestBody?: UpdateConsultationRequestBody;
}
export interface UpdateEpisodeOwnerRequest {
    episodeId: string;
    episodeOwnerRequestBody?: EpisodeOwnerRequestBody;
}
export interface UpdateEpisodeRolesRequest {
    episodeId: string;
    updateEpisodeRolesRequestBody?: UpdateEpisodeRolesRequestBody;
}
export interface UpdateQuestionsForEpisodeRequest {
    episodeId: string;
    updateQuestionsForEpisodeRequestBody: UpdateQuestionsForEpisodeRequestBody;
}
export interface UpdateVirtualCareTypeRequest {
    id: string;
    updateVirtualCareTypeRequestBody?: UpdateVirtualCareTypeRequestBody;
}


export interface IEpisodesOfCareApi {
      addTimelineEvent: (requestParameters: AddTimelineEventRequest) => Promise<TimelineEventOutput>
      archiveEpisodeOfCare: (requestParameters: ArchiveEpisodeOfCareRequest) => Promise<EpisodeOutput>
      createCtmCompletedCalculator: (requestParameters: CreateCtmCompletedCalculatorRequest) => Promise<ComponentDetailsOutput>
      createOrAssignEpisodeToAnAppointment: (requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest) => Promise<AppointmentSuccessfullyAssignedResponse>
      fetchArchiveEpisodeOfCareInfo: (requestParameters: FetchArchiveEpisodeOfCareInfoRequest) => Promise<ArchiveEpisodeOfCareInfoBody>
      fetchEpisodeConsultationHistory: (requestParameters: FetchEpisodeConsultationHistoryRequest) => Promise<Array<object>>
      fetchEpisodeOfCare: (requestParameters: FetchEpisodeOfCareRequest) => Promise<TimelineEpisodeOutput>
      fetchEpisodeOfCareTimelineEvents: (requestParameters: FetchEpisodeOfCareTimelineEventsRequest) => Promise<TimelineEpisodeTimelineEventsOutput>
      fetchEpisodeRoles: (requestParameters: FetchEpisodeRolesRequest) => Promise<EpisodeRolesOutputSchema>
      getInitialCalculatorAnswers: (requestParameters: GetInitialCalculatorAnswersRequest) => Promise<CalculatorInitialAnswersResponse>
      listCalculators: (requestParameters: ListCalculatorsRequest) => Promise<ListCalculatorsResponse>
      searchAvailableQuestionSetsForEpisode: (requestParameters: SearchAvailableQuestionSetsForEpisodeRequest) => Promise<XopQuestionSetSearchOutputSchema>
      searchCommonQuestions: (requestParameters: SearchCommonQuestionsRequest) => Promise<XopQuestionSearchOutputSchema>
      startEpisodeOfCare: (requestParameters: StartEpisodeOfCareRequest) => Promise<EpisodeOutput>
      updateConsultation: (requestParameters: UpdateConsultationRequest) => Promise<void>
      updateEpisodeOwner: (requestParameters: UpdateEpisodeOwnerRequest) => Promise<EpisodeSummary>
      updateEpisodeRoles: (requestParameters: UpdateEpisodeRolesRequest) => Promise<EpisodesRolesUpdatedResponse>
      updateQuestionsForEpisode: (requestParameters: UpdateQuestionsForEpisodeRequest) => Promise<UpdateQuestionsForEpisodeResponse>
      updateVirtualCareType: (requestParameters: UpdateVirtualCareTypeRequest) => Promise<void>
      addTimelineEventWithResponse: (requestParameters: AddTimelineEventRequest) => Promise<{ value: TimelineEventOutput, response: any}>
      archiveEpisodeOfCareWithResponse: (requestParameters: ArchiveEpisodeOfCareRequest) => Promise<{ value: EpisodeOutput, response: any}>
      createCtmCompletedCalculatorWithResponse: (requestParameters: CreateCtmCompletedCalculatorRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      createOrAssignEpisodeToAnAppointmentWithResponse: (requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest) => Promise<{ value: AppointmentSuccessfullyAssignedResponse, response: any}>
      fetchArchiveEpisodeOfCareInfoWithResponse: (requestParameters: FetchArchiveEpisodeOfCareInfoRequest) => Promise<{ value: ArchiveEpisodeOfCareInfoBody, response: any}>
      fetchEpisodeConsultationHistoryWithResponse: (requestParameters: FetchEpisodeConsultationHistoryRequest) => Promise<{ value: Array<object>, response: any}>
      fetchEpisodeOfCareWithResponse: (requestParameters: FetchEpisodeOfCareRequest) => Promise<{ value: TimelineEpisodeOutput, response: any}>
      fetchEpisodeOfCareTimelineEventsWithResponse: (requestParameters: FetchEpisodeOfCareTimelineEventsRequest) => Promise<{ value: TimelineEpisodeTimelineEventsOutput, response: any}>
      fetchEpisodeRolesWithResponse: (requestParameters: FetchEpisodeRolesRequest) => Promise<{ value: EpisodeRolesOutputSchema, response: any}>
      getInitialCalculatorAnswersWithResponse: (requestParameters: GetInitialCalculatorAnswersRequest) => Promise<{ value: CalculatorInitialAnswersResponse, response: any}>
      listCalculatorsWithResponse: (requestParameters: ListCalculatorsRequest) => Promise<{ value: ListCalculatorsResponse, response: any}>
      searchAvailableQuestionSetsForEpisodeWithResponse: (requestParameters: SearchAvailableQuestionSetsForEpisodeRequest) => Promise<{ value: XopQuestionSetSearchOutputSchema, response: any}>
      searchCommonQuestionsWithResponse: (requestParameters: SearchCommonQuestionsRequest) => Promise<{ value: XopQuestionSearchOutputSchema, response: any}>
      startEpisodeOfCareWithResponse: (requestParameters: StartEpisodeOfCareRequest) => Promise<{ value: EpisodeOutput, response: any}>
      updateConsultationWithResponse: (requestParameters: UpdateConsultationRequest) => Promise<{ value: void, response: any}>
      updateEpisodeOwnerWithResponse: (requestParameters: UpdateEpisodeOwnerRequest) => Promise<{ value: EpisodeSummary, response: any}>
      updateEpisodeRolesWithResponse: (requestParameters: UpdateEpisodeRolesRequest) => Promise<{ value: EpisodesRolesUpdatedResponse, response: any}>
      updateQuestionsForEpisodeWithResponse: (requestParameters: UpdateQuestionsForEpisodeRequest) => Promise<{ value: UpdateQuestionsForEpisodeResponse, response: any}>
      updateVirtualCareTypeWithResponse: (requestParameters: UpdateVirtualCareTypeRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class EpisodesOfCareApi extends runtime.BaseAPI {

    /**
     * Add a timeline event to an episode
     * Add a timeline event
     */
    addTimelineEventRaw = async (requestParameters: AddTimelineEventRequest): Promise<{ runtime: runtime.ApiResponse<TimelineEventOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addTimelineEvent.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/timeline_event`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddTimelineEventRequestBodyToJSON(requestParameters.addTimelineEventRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimelineEventOutputFromJSON(jsonValue)), response };
    }

    /**
     * Add a timeline event to an episode
     * Add a timeline event
     */
    addTimelineEvent = async (requestParameters: AddTimelineEventRequest): Promise<TimelineEventOutput> => {
        const { runtime } = await this.addTimelineEventRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Add a timeline event to an episode
     * Add a timeline event
     */
    addTimelineEventWithResponse = async (requestParameters: AddTimelineEventRequest): Promise<{ value: TimelineEventOutput, response: any}> => {
        const { runtime, response } = await this.addTimelineEventRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Archive an episode of care
     * Archive an episode of care
     */
    archiveEpisodeOfCareRaw = async (requestParameters: ArchiveEpisodeOfCareRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveEpisodeOfCare.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ArchiveEpisodeOfCareRequestBodyToJSON(requestParameters.archiveEpisodeOfCareRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeOutputFromJSON(jsonValue)), response };
    }

    /**
     * Archive an episode of care
     * Archive an episode of care
     */
    archiveEpisodeOfCare = async (requestParameters: ArchiveEpisodeOfCareRequest): Promise<EpisodeOutput> => {
        const { runtime } = await this.archiveEpisodeOfCareRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Archive an episode of care
     * Archive an episode of care
     */
    archiveEpisodeOfCareWithResponse = async (requestParameters: ArchiveEpisodeOfCareRequest): Promise<{ value: EpisodeOutput, response: any}> => {
        const { runtime, response } = await this.archiveEpisodeOfCareRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create ctm completed calculator for episode
     * Create ctm completed calculator for episode
     */
    createCtmCompletedCalculatorRaw = async (requestParameters: CreateCtmCompletedCalculatorRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling createCtmCompletedCalculator.');
        }

                        if (requestParameters.calculatorId === null || requestParameters.calculatorId === undefined) {
            throw new runtime.RequiredError('calculatorId','Required parameter requestParameters.calculatorId was null or undefined when calling createCtmCompletedCalculator.');
        }

                        if (requestParameters.completeCalculatorRequestBody === null || requestParameters.completeCalculatorRequestBody === undefined) {
            throw new runtime.RequiredError('completeCalculatorRequestBody','Required parameter requestParameters.completeCalculatorRequestBody was null or undefined when calling createCtmCompletedCalculator.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/calculators/{calculator_id}/complete`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"calculator_id"}}`, encodeURIComponent(String(requestParameters.calculatorId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteCalculatorRequestBodyToJSON(requestParameters.completeCalculatorRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Create ctm completed calculator for episode
     * Create ctm completed calculator for episode
     */
    createCtmCompletedCalculator = async (requestParameters: CreateCtmCompletedCalculatorRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.createCtmCompletedCalculatorRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create ctm completed calculator for episode
     * Create ctm completed calculator for episode
     */
    createCtmCompletedCalculatorWithResponse = async (requestParameters: CreateCtmCompletedCalculatorRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.createCtmCompletedCalculatorRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Creates an episode of care from an appointment_id or updates exiting one
     * Creates an episode of care from an appointment_id or updates exiting one
     */
    createOrAssignEpisodeToAnAppointmentRaw = async (requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentSuccessfullyAssignedResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/create_or_assign_episode_to_an_appointment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrAssignEpisodeToAnAppointmentRequestBodyToJSON(requestParameters.createOrAssignEpisodeToAnAppointmentRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentSuccessfullyAssignedResponseFromJSON(jsonValue)), response };
    }

    /**
     * Creates an episode of care from an appointment_id or updates exiting one
     * Creates an episode of care from an appointment_id or updates exiting one
     */
    createOrAssignEpisodeToAnAppointment = async (requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest): Promise<AppointmentSuccessfullyAssignedResponse> => {
        const { runtime } = await this.createOrAssignEpisodeToAnAppointmentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Creates an episode of care from an appointment_id or updates exiting one
     * Creates an episode of care from an appointment_id or updates exiting one
     */
    createOrAssignEpisodeToAnAppointmentWithResponse = async (requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest): Promise<{ value: AppointmentSuccessfullyAssignedResponse, response: any}> => {
        const { runtime, response } = await this.createOrAssignEpisodeToAnAppointmentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Archived episode info
     * Archived episode info
     */
    fetchArchiveEpisodeOfCareInfoRaw = async (requestParameters: FetchArchiveEpisodeOfCareInfoRequest): Promise<{ runtime: runtime.ApiResponse<ArchiveEpisodeOfCareInfoBody>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchArchiveEpisodeOfCareInfo.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/archive_info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ArchiveEpisodeOfCareInfoBodyFromJSON(jsonValue)), response };
    }

    /**
     * Archived episode info
     * Archived episode info
     */
    fetchArchiveEpisodeOfCareInfo = async (requestParameters: FetchArchiveEpisodeOfCareInfoRequest): Promise<ArchiveEpisodeOfCareInfoBody> => {
        const { runtime } = await this.fetchArchiveEpisodeOfCareInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Archived episode info
     * Archived episode info
     */
    fetchArchiveEpisodeOfCareInfoWithResponse = async (requestParameters: FetchArchiveEpisodeOfCareInfoRequest): Promise<{ value: ArchiveEpisodeOfCareInfoBody, response: any}> => {
        const { runtime, response } = await this.fetchArchiveEpisodeOfCareInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch episode consultation history
     */
    fetchEpisodeConsultationHistoryRaw = async (requestParameters: FetchEpisodeConsultationHistoryRequest): Promise<{ runtime: runtime.ApiResponse<Array<object>>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeConsultationHistory.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/consultation_history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse<any>(response), response };
    }

    /**
     * Fetch episode consultation history
     */
    fetchEpisodeConsultationHistory = async (requestParameters: FetchEpisodeConsultationHistoryRequest): Promise<Array<object>> => {
        const { runtime } = await this.fetchEpisodeConsultationHistoryRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch episode consultation history
     */
    fetchEpisodeConsultationHistoryWithResponse = async (requestParameters: FetchEpisodeConsultationHistoryRequest): Promise<{ value: Array<object>, response: any}> => {
        const { runtime, response } = await this.fetchEpisodeConsultationHistoryRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch an episode by ID
     * Fetch an episode of care
     */
    fetchEpisodeOfCareRaw = async (requestParameters: FetchEpisodeOfCareRequest): Promise<{ runtime: runtime.ApiResponse<TimelineEpisodeOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeOfCare.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.memberId !== undefined) {
            queryParameters['member_id'] = requestParameters.memberId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimelineEpisodeOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch an episode by ID
     * Fetch an episode of care
     */
    fetchEpisodeOfCare = async (requestParameters: FetchEpisodeOfCareRequest): Promise<TimelineEpisodeOutput> => {
        const { runtime } = await this.fetchEpisodeOfCareRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch an episode by ID
     * Fetch an episode of care
     */
    fetchEpisodeOfCareWithResponse = async (requestParameters: FetchEpisodeOfCareRequest): Promise<{ value: TimelineEpisodeOutput, response: any}> => {
        const { runtime, response } = await this.fetchEpisodeOfCareRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch an episode Timeline events by episode ID
     * Fetch timeline events for an episode of care
     */
    fetchEpisodeOfCareTimelineEventsRaw = async (requestParameters: FetchEpisodeOfCareTimelineEventsRequest): Promise<{ runtime: runtime.ApiResponse<TimelineEpisodeTimelineEventsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeOfCareTimelineEvents.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.order !== undefined) {
            queryParameters['order'] = requestParameters.order;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/timeline_events`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimelineEpisodeTimelineEventsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch an episode Timeline events by episode ID
     * Fetch timeline events for an episode of care
     */
    fetchEpisodeOfCareTimelineEvents = async (requestParameters: FetchEpisodeOfCareTimelineEventsRequest): Promise<TimelineEpisodeTimelineEventsOutput> => {
        const { runtime } = await this.fetchEpisodeOfCareTimelineEventsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch an episode Timeline events by episode ID
     * Fetch timeline events for an episode of care
     */
    fetchEpisodeOfCareTimelineEventsWithResponse = async (requestParameters: FetchEpisodeOfCareTimelineEventsRequest): Promise<{ value: TimelineEpisodeTimelineEventsOutput, response: any}> => {
        const { runtime, response } = await this.fetchEpisodeOfCareTimelineEventsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch roles of an episode
     * Fetch roles of an episode
     */
    fetchEpisodeRolesRaw = async (requestParameters: FetchEpisodeRolesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeRolesOutputSchema>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling fetchEpisodeRoles.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/roles`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeRolesOutputSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch roles of an episode
     * Fetch roles of an episode
     */
    fetchEpisodeRoles = async (requestParameters: FetchEpisodeRolesRequest): Promise<EpisodeRolesOutputSchema> => {
        const { runtime } = await this.fetchEpisodeRolesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch roles of an episode
     * Fetch roles of an episode
     */
    fetchEpisodeRolesWithResponse = async (requestParameters: FetchEpisodeRolesRequest): Promise<{ value: EpisodeRolesOutputSchema, response: any}> => {
        const { runtime, response } = await this.fetchEpisodeRolesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get available initial answers for a calculator
     * Get initial answers for an initial calculator
     */
    getInitialCalculatorAnswersRaw = async (requestParameters: GetInitialCalculatorAnswersRequest): Promise<{ runtime: runtime.ApiResponse<CalculatorInitialAnswersResponse>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getInitialCalculatorAnswers.');
        }

                        if (requestParameters.calculatorId === null || requestParameters.calculatorId === undefined) {
            throw new runtime.RequiredError('calculatorId','Required parameter requestParameters.calculatorId was null or undefined when calling getInitialCalculatorAnswers.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/calculators/{calculator_id}/initial_answers`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"calculator_id"}}`, encodeURIComponent(String(requestParameters.calculatorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CalculatorInitialAnswersResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get available initial answers for a calculator
     * Get initial answers for an initial calculator
     */
    getInitialCalculatorAnswers = async (requestParameters: GetInitialCalculatorAnswersRequest): Promise<CalculatorInitialAnswersResponse> => {
        const { runtime } = await this.getInitialCalculatorAnswersRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get available initial answers for a calculator
     * Get initial answers for an initial calculator
     */
    getInitialCalculatorAnswersWithResponse = async (requestParameters: GetInitialCalculatorAnswersRequest): Promise<{ value: CalculatorInitialAnswersResponse, response: any}> => {
        const { runtime, response } = await this.getInitialCalculatorAnswersRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get calculators by CTM service lines
     * Get available calculators for episode
     */
    listCalculatorsRaw = async (requestParameters: ListCalculatorsRequest): Promise<{ runtime: runtime.ApiResponse<ListCalculatorsResponse>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling listCalculators.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/calculators`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListCalculatorsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get calculators by CTM service lines
     * Get available calculators for episode
     */
    listCalculators = async (requestParameters: ListCalculatorsRequest): Promise<ListCalculatorsResponse> => {
        const { runtime } = await this.listCalculatorsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get calculators by CTM service lines
     * Get available calculators for episode
     */
    listCalculatorsWithResponse = async (requestParameters: ListCalculatorsRequest): Promise<{ value: ListCalculatorsResponse, response: any}> => {
        const { runtime, response } = await this.listCalculatorsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch available question sets by CTM service lines
     * Search available question sets for episode
     */
    searchAvailableQuestionSetsForEpisodeRaw = async (requestParameters: SearchAvailableQuestionSetsForEpisodeRequest): Promise<{ runtime: runtime.ApiResponse<XopQuestionSetSearchOutputSchema>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling searchAvailableQuestionSetsForEpisode.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/question_sets`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => XopQuestionSetSearchOutputSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch available question sets by CTM service lines
     * Search available question sets for episode
     */
    searchAvailableQuestionSetsForEpisode = async (requestParameters: SearchAvailableQuestionSetsForEpisodeRequest): Promise<XopQuestionSetSearchOutputSchema> => {
        const { runtime } = await this.searchAvailableQuestionSetsForEpisodeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch available question sets by CTM service lines
     * Search available question sets for episode
     */
    searchAvailableQuestionSetsForEpisodeWithResponse = async (requestParameters: SearchAvailableQuestionSetsForEpisodeRequest): Promise<{ value: XopQuestionSetSearchOutputSchema, response: any}> => {
        const { runtime, response } = await this.searchAvailableQuestionSetsForEpisodeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch common questions by query, filtered by sex
     * Search available common questions for episode
     */
    searchCommonQuestionsRaw = async (requestParameters: SearchCommonQuestionsRequest): Promise<{ runtime: runtime.ApiResponse<XopQuestionSearchOutputSchema>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling searchCommonQuestions.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/common_questions`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => XopQuestionSearchOutputSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch common questions by query, filtered by sex
     * Search available common questions for episode
     */
    searchCommonQuestions = async (requestParameters: SearchCommonQuestionsRequest): Promise<XopQuestionSearchOutputSchema> => {
        const { runtime } = await this.searchCommonQuestionsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch common questions by query, filtered by sex
     * Search available common questions for episode
     */
    searchCommonQuestionsWithResponse = async (requestParameters: SearchCommonQuestionsRequest): Promise<{ value: XopQuestionSearchOutputSchema, response: any}> => {
        const { runtime, response } = await this.searchCommonQuestionsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Start an episode of care
     * Start an episode of care
     */
    startEpisodeOfCareRaw = async (requestParameters: StartEpisodeOfCareRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeOutput>, response: any}> => {
        if (requestParameters.episodeCreationInput === null || requestParameters.episodeCreationInput === undefined) {
            throw new runtime.RequiredError('episodeCreationInput','Required parameter requestParameters.episodeCreationInput was null or undefined when calling startEpisodeOfCare.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EpisodeCreationInputToJSON(requestParameters.episodeCreationInput),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeOutputFromJSON(jsonValue)), response };
    }

    /**
     * Start an episode of care
     * Start an episode of care
     */
    startEpisodeOfCare = async (requestParameters: StartEpisodeOfCareRequest): Promise<EpisodeOutput> => {
        const { runtime } = await this.startEpisodeOfCareRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Start an episode of care
     * Start an episode of care
     */
    startEpisodeOfCareWithResponse = async (requestParameters: StartEpisodeOfCareRequest): Promise<{ value: EpisodeOutput, response: any}> => {
        const { runtime, response } = await this.startEpisodeOfCareRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update consultation flag for an episode of care
     * Update consultation flag for an episode of care
     */
    updateConsultationRaw = async (requestParameters: UpdateConsultationRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConsultation.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/update_consultation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConsultationRequestBodyToJSON(requestParameters.updateConsultationRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update consultation flag for an episode of care
     * Update consultation flag for an episode of care
     */
    updateConsultation = async (requestParameters: UpdateConsultationRequest): Promise<void> => {
        const { runtime } = await this.updateConsultationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update consultation flag for an episode of care
     * Update consultation flag for an episode of care
     */
    updateConsultationWithResponse = async (requestParameters: UpdateConsultationRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.updateConsultationRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Update owner assigned to an episode
     * Update owner of an episode
     */
    updateEpisodeOwnerRaw = async (requestParameters: UpdateEpisodeOwnerRequest): Promise<{ runtime: runtime.ApiResponse<EpisodeSummary>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateEpisodeOwner.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/owner`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EpisodeOwnerRequestBodyToJSON(requestParameters.episodeOwnerRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodeSummaryFromJSON(jsonValue)), response };
    }

    /**
     * Update owner assigned to an episode
     * Update owner of an episode
     */
    updateEpisodeOwner = async (requestParameters: UpdateEpisodeOwnerRequest): Promise<EpisodeSummary> => {
        const { runtime } = await this.updateEpisodeOwnerRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update owner assigned to an episode
     * Update owner of an episode
     */
    updateEpisodeOwnerWithResponse = async (requestParameters: UpdateEpisodeOwnerRequest): Promise<{ value: EpisodeSummary, response: any}> => {
        const { runtime, response } = await this.updateEpisodeOwnerRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update roles assigned to an episode
     * Update roles of an episode
     */
    updateEpisodeRolesRaw = async (requestParameters: UpdateEpisodeRolesRequest): Promise<{ runtime: runtime.ApiResponse<EpisodesRolesUpdatedResponse>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateEpisodeRoles.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/roles`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEpisodeRolesRequestBodyToJSON(requestParameters.updateEpisodeRolesRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EpisodesRolesUpdatedResponseFromJSON(jsonValue)), response };
    }

    /**
     * Update roles assigned to an episode
     * Update roles of an episode
     */
    updateEpisodeRoles = async (requestParameters: UpdateEpisodeRolesRequest): Promise<EpisodesRolesUpdatedResponse> => {
        const { runtime } = await this.updateEpisodeRolesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update roles assigned to an episode
     * Update roles of an episode
     */
    updateEpisodeRolesWithResponse = async (requestParameters: UpdateEpisodeRolesRequest): Promise<{ value: EpisodesRolesUpdatedResponse, response: any}> => {
        const { runtime, response } = await this.updateEpisodeRolesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update questions collection based on changed source question sets
     * Update questions collection
     */
    updateQuestionsForEpisodeRaw = async (requestParameters: UpdateQuestionsForEpisodeRequest): Promise<{ runtime: runtime.ApiResponse<UpdateQuestionsForEpisodeResponse>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateQuestionsForEpisode.');
        }

                        if (requestParameters.updateQuestionsForEpisodeRequestBody === null || requestParameters.updateQuestionsForEpisodeRequestBody === undefined) {
            throw new runtime.RequiredError('updateQuestionsForEpisodeRequestBody','Required parameter requestParameters.updateQuestionsForEpisodeRequestBody was null or undefined when calling updateQuestionsForEpisode.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/questions/update`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateQuestionsForEpisodeRequestBodyToJSON(requestParameters.updateQuestionsForEpisodeRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UpdateQuestionsForEpisodeResponseFromJSON(jsonValue)), response };
    }

    /**
     * Update questions collection based on changed source question sets
     * Update questions collection
     */
    updateQuestionsForEpisode = async (requestParameters: UpdateQuestionsForEpisodeRequest): Promise<UpdateQuestionsForEpisodeResponse> => {
        const { runtime } = await this.updateQuestionsForEpisodeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update questions collection based on changed source question sets
     * Update questions collection
     */
    updateQuestionsForEpisodeWithResponse = async (requestParameters: UpdateQuestionsForEpisodeRequest): Promise<{ value: UpdateQuestionsForEpisodeResponse, response: any}> => {
        const { runtime, response } = await this.updateQuestionsForEpisodeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update virtual_care_type for an episode of care
     * Update virtual_care_type for an episode of care
     */
    updateVirtualCareTypeRaw = async (requestParameters: UpdateVirtualCareTypeRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateVirtualCareType.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/virtual_urgent_care_history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateVirtualCareTypeRequestBodyToJSON(requestParameters.updateVirtualCareTypeRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update virtual_care_type for an episode of care
     * Update virtual_care_type for an episode of care
     */
    updateVirtualCareType = async (requestParameters: UpdateVirtualCareTypeRequest): Promise<void> => {
        const { runtime } = await this.updateVirtualCareTypeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update virtual_care_type for an episode of care
     * Update virtual_care_type for an episode of care
     */
    updateVirtualCareTypeWithResponse = async (requestParameters: UpdateVirtualCareTypeRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.updateVirtualCareTypeRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchEpisodesOfCareApi: IEpisodesOfCareApi  = new EpisodesOfCareApi();
